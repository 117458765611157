import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import profilePic from "../../../assets/images/Profile-Picture.jpg";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import SortIcon from "@mui/icons-material/Sort";
import FilterIcon from "@mui/icons-material/Filter";
import { Grid, Button } from "@mui/material";
import Slider from "react-slick";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import moment from "moment/moment";
import SettingsVoiceOutlinedIcon from "@mui/icons-material/SettingsVoiceOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// For Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// End

import afterBef1 from "../../../assets/images/afterBef1.png";
import afterBef2 from "../../../assets/images/afterBef2.png";
import CloseIcon from "@mui/icons-material/Close";
import userWithCheck from "../../../assets/images/userWithCheck.svg";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { makeGetRequest, makePostRequest } from "../../../utils/utils";
import swal from "sweetalert";
import ToggleButton from "@mui/material/ToggleButton";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AppDrawer from "../../AppDrawer";

// For Accordian
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// End

import pencil from "../../../assets/images/pencil.svg";
import availabilityPreview from "../../../assets/images/availabilityPreview.svg";
import Switch from "@mui/material/Switch";
import avarPi from "../../../assets/images/avtarPic.png";
import speak_animation from "../../../assets/images/speak_animation.gif";
import { DateRange } from "@mui/icons-material";
const label = { inputProps: { "aria-label": "Switch demo" } };

// For Tabs
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

// Tab Inner
function CustomTabPanel2(props) {
  const { children, value2, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value2 !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value2 === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel2.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value2: PropTypes.number.isRequired,
};

function a11yProps2(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// End

function AdminTasksList() {
  // For Days Slider
  const daysSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // End

  // For According 2
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange3 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // End
  // For Accordian 4
  const [expanded4, setExpanded4] = React.useState(false);

  const handleChange4 = (panel4) => (event, isExpanded) => {
    setExpanded4(isExpanded ? panel4 : false);
  };
  // End

  //For Scroll Add Class
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        // Adjust this value based on your needs
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // End

  const settings = {
    focusOnSelect: true,
    slidesToScroll: 1,
    className: "center",
    centerMode: false,
    infinite: false,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
  };

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // Track the current slide index

  const activeind=[
    "Pref",
    "M","T","W","Th","F","S","Su"
  ]

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => {
      setCurrentSlideIndex(current); // Update the current slide index after the slide changes
      SetAbb(activeind[current])
    },
  };

  // For Modal
  const [openAval, setOpenAval] = React.useState(false);

  const [sch_ids, SetSch_id] = React.useState("");
  const handleClickOpenAval = (sch_ids) => {
    console.log(">>", sch_ids);

    setShowLoader(true);
    const initialStartDate = mondays[0].format("YYYY-MM-DD");
    const initialEndDate = mondays[0]
      .clone()
      .add(6, "days")
      .format("YYYY-MM-DD");

    setDateRange({ startDate: initialStartDate, endDate: initialEndDate });
    SetSch_id(sch_ids);

    getEmployeeAvailability(initialStartDate, initialEndDate, sch_ids);
    setOpenAval(true);
  };

  const handleCloseAval = () => {
    setOpenAval(false);
  };
  // End

  // Tab Inner
  const [value2, setValue2] = React.useState(0);

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };
  // End

  const navigate = useNavigate();
  const [showLoader, setShowLoader] = React.useState(false);
  const [upload, setUpload] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("desc");
  const [pageNo, setPageNo] = React.useState(1);
  const [sortColumnName, setSortColumnName] = React.useState("created_at");
  const [selected, setSelected] = React.useState(false);
  const [offsetvalue, setoffsetvalue] = React.useState(0);
  const [itemsPerPage] = React.useState(1000);
  const [totalItems, setTotalItems] = React.useState(0);
  const [categoryList, setCategoryList] = React.useState([]);
  const [taskList, setTaskList] = React.useState([]);
  const [frequancyList, setFrequancyList] = React.useState([]);
  const [schedulelist, setScheduleList] = React.useState([]);
  const [searchText, setSearchText] = useState("");
  const [catsearchText, setCatSearchText] = useState("");
  const [fresearchText, setFreSearchText] = useState("");
  const [tempSlide, setTempSlide] = React.useState("");
  const [selectedTaskList, setSelectedTaskList] = React.useState();
  const [selectedCategorylist, setSelectedCategorylist] = React.useState();
  const [isListening, setIsListening] = useState(false);

  function handleChangeSortBy() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllTaskList(searchText);
  }

  // For Tabs
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 0) {
      setSearchText("");
      setCatSearchText("");
      setFreSearchText("");
      checkDelete();
      getAllTaskList(null);
    } else if (newValue == 1) {
      setSearchText("");
      setCatSearchText("");
      setFreSearchText("");
      getAllCategoryList(null);
    } else if (newValue == 2) {
      setSearchText("");
      setCatSearchText("");
      setFreSearchText("");
      checkCatDelete();
      getAllFrequancyList(null);
    } else {
      setSearchText("");
      setCatSearchText("");
      setFreSearchText("");
      checkDelete();
      getAllTaskList(null);
    }

    localStorage.removeItem("TabName");
  };
  // End

  // For Drop Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const OpenDrop = Boolean(anchorEl);
  const handleClickOpenDrop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseOpenDrop = () => {
    setAnchorEl(null);
  };
  // End

  const swipeNSelection = (event) => {
    forSelectList(event);
    resetSwipeDirection(event);
  };

  const forSelectList = () => {
    // var element = document.getElementById("listID");
    // element.classList.toggle("selectedList");
  };

  // For Modal
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // End

  // For Swipe Left Right
  const [swipeDirection, setSwipeDirection] = useState(null);
  const startXRef = useRef(null);
  const threshold = 50;

  const handleTouchStart = (e, index) => {
    startXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e, index) => {
    const currentX = e.touches[0].clientX;
    const distance = currentX - startXRef.current;

    if (Math.abs(distance) > threshold) {
      if (distance > 0) {
        var element = document.getElementById("bugfree-" + tempSlide);
        if (element != null) {
          element.classList.remove("swiped-right");
          element.classList.remove("swiped-left");
        }

        var elementnew = document.getElementById("bugfree-" + index);
        elementnew.classList.toggle("swiped-right");
        setTempSlide(index);
        //setSwipeDirection("right");
      } else {
        //setSwipeDirection("left");
        var element = document.getElementById("bugfree-" + tempSlide);
        if (element != null) {
          element.classList.remove("swiped-left");
          element.classList.remove("swiped-right");
        }

        var elementnew = document.getElementById("bugfree-" + index);
        elementnew.classList.toggle("swiped-left");
        setTempSlide(index);
      }
    }
  };

  const handleTouchMove2 = (e) => {
    const currentX = e.touches[0].clientX;
    const distance = currentX - startXRef.current;

    if (Math.abs(distance) > threshold) {
      if (distance > 0) {
        setSwipeDirection("right");
      } else {
        setSwipeDirection("left");
      }
    }
  };

  const handleTouchEnd = () => {
    var element = document.getElementById("bugfree-" + tempSlide);
    if (element != null) {
      element.classList.remove("swiped-right");
      element.classList.remove("swiped-left");
    }
    // Optionally, you could reset the swipeDirection here if you want to clear it after some time
    // setSwipeDirection(null);
  };

  const resetSwipeDirection = () => {
    setSwipeDirection(null);
  };
  // Swipe Left Right End

  async function getAllTaskList(searchString) {
    if (!searchString) {
      searchString = null;
      setSearchText(null);
    } else {
      setSearchText(searchString);
    }
    //setShowLoader(true);
    await makeGetRequest(
      "task/tasksearch/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/" +
        offsetvalue +
        "/" +
        itemsPerPage
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setTaskList(response.data.data.res);
            setTotalItems(response.data.data.totalCount);
            console.log("Task", response.data.data.totalCount);
          } else setTaskList([]);
          setUpload(!upload);
        }
        //setShowLoader(false);
        setSortColumnName("created_at");
      })
      .catch((err) => {
        //setShowLoader(false);
      });
  }

  async function checkDelete() {
    makeGetRequest("schedular/gettaskschedularById")
      .then((response) => {
        if (response.data.data.rows.length > 0) {
          setSelectedTaskList(response.data.data.rows);
        } else {
          setSelectedTaskList();
        }
      })
      .catch((err) => {});
  }

  async function checkCatDelete() {
    makeGetRequest("schedular/getcategoryschedularById")
      .then((response) => {
        if (response.data.data.rows.length > 0) {
          setSelectedCategorylist(response.data.data.rows);
        } else {
          setSelectedCategorylist();
        }
      })
      .catch((err) => {});
  }

  async function deleteTask(taskId) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record? This process cannot be undone.",
      icon: "warning",
      buttons: ["No", "Yes"],
    });

    if (willDelete) {
      var bodyFormData = {
        taskid: taskId,
        is_deleted: true,
      };
      await makePostRequest("task/updatetaskstatus", bodyFormData)
        .then((response) => {
          if (response.data) {
            swal("Success", "Record deleted successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            getAllTaskList(searchText);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function add(arr, name) {
    if (arr != undefined) {
      const { length } = arr;
      const id = length + 1;
      const found = arr.some((el) => el.taskid === name);
      let res = false;
      if (!found) {
        res = false;
      } else {
        res = true;
      }
      return res;
    }
  }

  function addcat(arr, name) {
    if (arr != undefined) {
      const { length } = arr;
      const id = length + 1;
      const found = arr.some((el) => el.categoryid === name);
      let res = false;
      if (!found) {
        res = false;
      } else {
        res = true;
      }
      return res;
    }
  }

  const editTask = (taskObj) => {
    navigate("/AdminAddTask", {
      state: { Id: taskObj.taskid },
    });
  };

  function handleChangeSortByTask() {
    setSortColumnName("task_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllTaskList(searchText);
  }

  async function getAllCategoryList(searchString) {
    if (!searchString) {
      searchString = null;
      setCatSearchText(null);
    } else {
      setCatSearchText(searchString);
    }
    //setShowLoader(true);
    await makeGetRequest(
      "category/categorysearch/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/" +
        offsetvalue +
        "/" +
        itemsPerPage
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setCategoryList(response.data.data.res);
            setTotalItems(response.data.data.totalCount);
            console.log("Category", response.data.data.totalCount);
          } else setCategoryList([]);
          setUpload(!upload);
        }
        //setShowLoader(false);
        setSortColumnName("created_at");
      })
      .catch((err) => {
        //setShowLoader(false);
      });
  }

  async function deleteCategory(categoryid) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record? This process cannot be undone.",
      icon: "warning",
      buttons: ["No", "Yes"],
    });

    if (willDelete) {
      var bodyFormData = {
        categoryid: categoryid,
        is_deleted: true,
      };
      await makePostRequest("category/updatecategorystatus", bodyFormData)
        .then((response) => {
          if (response.data) {
            swal("Success", "Record deleted successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            getAllCategoryList(null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const editCategory = (cateObj) => {
    navigate("/AddCategory", {
      state: { data: cateObj },
    });
  };

  function handleChangeSortByCategory() {
    setSortColumnName("frequancy_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllCategoryList(catsearchText);
  }

  async function getAllFrequancyList(searchString) {
    if (!searchString) {
      searchString = null;
      setFreSearchText(null);
    } else {
      setFreSearchText(searchString);
    }
    //setShowLoader(true);
    await makeGetRequest(
      "frequancy/frequancysearch/" +
        sortColumnName +
        "/" +
        sortBy +
        "/" +
        searchString +
        "/" +
        offsetvalue +
        "/" +
        itemsPerPage
    )
      .then((response) => {
        if (response.data.data.res) {
          if (response.data.data.res.length > 0) {
            setFrequancyList(response.data.data.res);
            setTotalItems(response.data.data.totalCount);
            console.log("frequancy", response.data.data.totalCount);
          } else setFrequancyList([]);
          setUpload(!upload);
        }
        //setShowLoader(false);
        setSortColumnName("created_at");
      })
      .catch((err) => {
        //setShowLoader(false);
      });
  }

  async function deleteFrequency(frequancyid) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Do you really want to delete this record? This process cannot be undone.",
      icon: "warning",
      buttons: ["No", "Yes"],
    });

    if (willDelete) {
      var bodyFormData = {
        frequancyid: frequancyid,
        is_deleted: true,
      };
      await makePostRequest("frequancy/updatefrequancystatus", bodyFormData)
        .then((response) => {
          if (response.data) {
            swal("Success", "Record deleted successfully", "success", {
              timer: 3000,
              buttons: false,
            });
            getAllFrequancyList(null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  const editFrequency = (frequObj) => {
    navigate("/AddFrequency", {
      state: { data: frequObj },
    });
  };

  const editSchedule = (schlist, template_name) => {
    localStorage.setItem("templatename", template_name);
    navigate("/CreateNewSchedule", {
      state: { data: schlist },
    });
  };

  function handleChangeSortByFrequency() {
    setSortColumnName("frequancy_name");
    setSelected(!selected);
    if (sortBy == "desc") setSortBy("asc");
    else setSortBy("desc");
    setUpload(!upload);
    getAllFrequancyList(fresearchText);
  }

  React.useEffect(() => {
    localStorage.removeItem("templatename");
    getStartWeekdate();
    checkDelete();
    getAllCategoryList(null);
    getAllTaskList(null);
    getAllFrequancyList(null);
    getAllScheduleList();
    getAllArchiveTemplateList();

    if (localStorage.getItem("TabName") == "frequency") {
      setValue(2);
      a11yProps(2);
    } else if (localStorage.getItem("TabName") == "Task") {
      // alert("hiii")
      setValue(1);
      a11yProps(1);
    } else if (localStorage.getItem("TabName") == "Category") {
      setValue(0);
      a11yProps(0);
    } else if (localStorage.getItem("TabName") == "Schedule") {
      setValue(3);
      a11yProps(3);
    } else {
      setValue(0);
      a11yProps(0);
    }
  }, [pageNo]);

  const [isActive, setIsActive] = useState(false);

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // Prepare a lookup to access the count and sum values based on weekdays
  const initialDayData = {
    shifts: {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    },
    emp: {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0,
    },
  };

  const [dayData, setDayData] = useState(initialDayData);

  const [tabValues, setTabValues] = useState({}); // To track active tab for each template
  const [templateDayData, setTemplateDayData] = useState({}); // To track dayData for each template

  // Function to handle tab change for each template
  const handleChangeTab = (templateName, newValue) => {
    setTabValues((prevTabValues) => ({
      ...prevTabValues,
      [templateName]: newValue,
    }));
  };

  async function getlocationwiseSummarycount(loc_name, template_name) {
    var bodyFormData = {
      location: loc_name,
      templatename: template_name,
    };

    await makePostRequest(
      "schedular/GetShiftAndEmpCountByLocation",
      bodyFormData
    )
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);

          const updatedDayData = {
            shifts: { ...initialDayData.shifts },
            emp: { ...initialDayData.emp },
          };

          // Update only the weekdays present in the API response
          response.data.data.forEach((item) => {
            updatedDayData.shifts[item.weekday] = item.count;
            updatedDayData.emp[item.weekday] = item.sum;
          });

          // Update dayData for the specific template
          setTemplateDayData((prevTemplateDayData) => ({
            ...prevTemplateDayData,
            [template_name]: updatedDayData,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getAllScheduleList() {
    await makeGetRequest(
      "schedular/getTemplateListForSummaryCount/" +
        parseInt(localStorage.getItem("companyId")) +
        "/false"
    )
      .then(async (response) => {
        if (response.data.data) {
          console.log("schedule", response.data.data);
          if (response.data.data.length > 0) {
            setScheduleList(response.data.data);
            // Fetch "All" summary count for each template
            for (const schlist of response.data.data) {
              await getlocationwiseSummarycount("All", schlist.template_name);
            }
          } else setScheduleList([]);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }

  const [archive, setArchiveList] = React.useState([]);
  async function getAllArchiveTemplateList() {
    await makeGetRequest("schedular/getArchiveTemplatesList")
      .then(async (response) => {
        if (response.data.data) {
          console.log("archive", response.data.data);
          if (response.data.data.length > 0) {
            setArchiveList(response.data.data);
          } else setArchiveList([]);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  }
  //

  // Handle switch toggle
  const handleSwitchChange = async (event, index, template_name) => {
    const updatedList = [...schedulelist]; // Clone the current state
    updatedList[index].is_active = event.target.checked; // Update the is_active for the specific item

    var bodyFormData = {
      template_name: template_name,
      is_active: event.target.checked,
    };

    await makePostRequest(
      "schedular/ChangeAdminScheduleTemplate",
      bodyFormData
    ).then((response) => {
      if (response.data) {
        setScheduleList(updatedList);
        getAllScheduleList(); // Update state
      }
    });
    console.log(
      `${updatedList[index].template_name} is now ${
        event.target.checked ? "active" : "inactive"
      }`
    );
  };

  const [taskname, setTaskname] = React.useState("");
  const [sound, setSound] = useState(0);

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Your browser does not support speech recognition. Please try Chrome."
      );
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setTaskname(transcript);
      if (transcript !== null) {
        addTaskList(transcript);
      }
      setIsListening(false);
    };

    const addTaskList = async (taskName) => {
      const formData = {
        task_name: taskName,
        status: true,
        is_deleted: false,
      };

      await makePostRequest("task/inserttask", formData)
        .then((response) => {
          console.log("error with response===>" + response);
          if (response.data) {
            console.log(response);
            getAllTaskList(null);
          }
          setTaskname("");
          setShowLoader(false);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    if (isListening) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    };
  }, [isListening]);

  const handleStartListening = () => {
    setIsListening(true);
    document.getElementById("task_name").focus();
    setTaskname("");
    setSound(1);
  };

  const [frequency, setFrequency] = React.useState("");
  const [isListeningFrequency, setIsListeningFrequency] = useState(false);

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Your browser does not support speech recognition. Please try Chrome."
      );
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setFrequency(transcript);
      if (transcript !== null) {
        addFrequency(transcript);
      }
      setIsListeningFrequency(false);
    };

    const addFrequency = async (frequency) => {
      const formData = {
        frequancy_name: frequency,
        is_deleted: false,
        status: true,
      };

      await makePostRequest("frequancy/insertfrequancy", formData)
        .then((response) => {
          console.log("error with response===>" + response);
          if (response.data) {
            console.log(response);
            getAllFrequancyList(null);
          }
          setFrequency("");
          setShowLoader(false);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    };

    recognition.onend = () => {
      setIsListeningFrequency(false);
    };

    if (isListeningFrequency) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    };
  }, [isListeningFrequency]);

  const handleStartListeningFrequency = () => {
    setIsListeningFrequency(true);
    document.getElementById("frequancy_name").focus();
    setFrequency(" ");
    setSound(1);
  };

  const [Category, setCategory] = React.useState("");
  const [isListeningCategory, setIsListeningCategory] = useState(false);

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      alert(
        "Your browser does not support speech recognition. Please try Chrome."
      );
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setCategory(transcript);
      if (transcript !== null) {
        addCategory(transcript);
      }
      setIsListeningCategory(false);
    };

    const addCategory = async (category) => {
      const formData = {
        category_name: category,
        frequancy_ids: [0],
        is_deleted: false,
        status: true,
      };

      await makePostRequest("category/insertcategory", formData)
        .then((response) => {
          console.log("error with response===>" + response);
          if (response.data) {
            console.log(response);
            getAllCategoryList(null);
          }
          setCategory("");
          setShowLoader(false);
        })
        .catch((err) => {
          console.log("Err", err);
        });
    };

    recognition.onend = () => {
      setIsListeningCategory(false);
    };

    if (isListeningCategory) {
      recognition.start();
    }

    return () => {
      recognition.stop();
    };
  }, [isListeningCategory]);

  const handleStartListeningCategory = () => {
    setIsListeningCategory(true);
    document.getElementById("category_name").focus();
    setCategory("");
    setSound(1);
  };

  //code by yamini
  const weekdayMap = {
    M: "Monday",
    T: "Tuesday",
    W: "Wednesday",
    Th: "Thursday",
    F: "Friday",
    S: "Saturday",
    Su: "Sunday",
  };

  const weekdays1 = Object.keys(weekdayMap);

  // Define `dateRange` with initial values as today's week range
  const [dateRange, setDateRange] = useState({
    startDate: moment().startOf("week").add(1, "days").format("YYYY-MM-DD"),
    endDate: moment().endOf("week").add(1, "days").format("YYYY-MM-DD"),
  });

  const [availweekstartdate, Setavailweekstartdate] = React.useState();
  const getStartWeekdate = () => {
    makeGetRequest("employee/getStartingWeekDate")
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data) {
          Setavailweekstartdate(response.data.data);
        } else {
          Setavailweekstartdate();
        }
      })
      .catch((err) => {});
  };

  const getMondays = (numWeeks) => {
    const mondays = [];
    let currentDate;
    // currentDate = moment();
    if (availweekstartdate == "") {
      currentDate = moment();
    } else {
      currentDate = moment(availweekstartdate);
    }

    // Get the current date

    // Find the previous Monday (or current day if it's Monday)
    const currentMonday = currentDate.startOf("week").add(1, "days");
    // const nextMonday = currentDate.startOf("week").add(1, "weeks").add(1, "days");

    for (let i = 0; i < numWeeks; i++) {
      mondays.push(currentMonday.clone().add(i * 7, "days"));
    }

    return mondays;
  };

  // Generate an array of Mondays for the next 'n' weeks
  const mondays = getMondays(10); // Adjust the number of weeks if needed

  const [selectedMonday, setSelectedMonday] = useState(mondays[0]); // Default to the first Monday
  let weekdayDates = [];
  const getCurrentWeekDates = (startDate) => {
    const weekDates = [];
    const startOfWeek = moment(startDate);
    const day = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    for (let i = 0; i < 7; i++) {
      weekDates.push(startOfWeek.clone().add(i, "days").format("YYYY-MM-DD"));
      weekdayDates[day[i]] = startOfWeek
        .clone()
        .add(i, "days")
        .format("YYYY-MM-DD");
    }

    return weekDates;
  };

  // Weekday dates will dynamically update based on `dateRange.startDate`
  const weekDates = getCurrentWeekDates(dateRange.startDate);

  // Handle Monday change from the slider
  const handleMondayChange = (newMonday) => {
    const startDate = newMonday.format("YYYY-MM-DD");
    const endDate = newMonday.clone().add(6, "days").format("YYYY-MM-DD");
    // mondays = getMondays(selectedMonday, 1);
    setDateRange({ startDate, endDate }); // Update state with the new date range

    getEmployeeAvailability(startDate, endDate, sch_ids);
  };

  const [availability, setAvailability] = React.useState({});
  const [availabilitystatus, setAvailabilityStatus] = React.useState([]);

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const sliderRef = useRef(null);
  const dayMapping = {
    M: "Monday",
    T: "Tuesday",
    W: "Wednesday",
    Th: "Thursday",
    F: "Friday",
    S: "Saturday",
    Su: "Sunday",
  };
  const weekdayAbbr = Object.keys(dayMapping);
  const [selectedDay, setSelectedDay] = useState("Monday"); // Initialize with "M" for Monday
  const [showPreferences, setShowPreferences] = useState(false); // State to show preferences

  // const handleDayClick = (day) => {
  //   console.log(day);
  //   setSelectedDay(dayMapping[day]); // Update the selected day state when a day is clicked

  //   // getEmployeeAvailability(initialStartDate, initialEndDate, sch_ids);
  // };

  // Handle Preferences click
  const handlePreferencesClick = () => {
    SetAbb('Pref');
    setShowPreferences(!showPreferences); // Toggle Preferences view
    if (sliderRef.current) {
      // Move slider to Preferences slide (index 0)
      sliderRef.current.slickGoTo(0);
    }
  };

  const [abb, SetAbb] = React.useState('Pref');
  const handleDayClick = (dayAbbreviation) => {
    const fullDay = dayMapping[dayAbbreviation]; // Get the full day name
    SetAbb(dayAbbreviation);
    setSelectedDay(fullDay); // Set the selected day as full name

    // Get the slide index based on the full day
    const dayIndex = weekdayAbbr.indexOf(dayAbbreviation) + 1;

    // Move slider to the selected day index
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(dayIndex);
    }
  };
  const getEmployeeAvailability = async (startDate, endDate, sch_ids) => {
    console.log(sch_ids);

    let formData = {
      startDate: startDate, // Pass the start date
      endDate: endDate, // Pass the end date
      sch_id: sch_ids,
    };
    setShowLoader(true);

    makePostRequest("employee/getAvailabilityData", formData)
      .then((response) => {
        const scheduleData = response?.data?.data;
        if (scheduleData && Object.keys(scheduleData).length > 0) {
          // Iterate through each day's data and populate selectedEmployees state based on is_emp_available
          setAvailability(scheduleData);
        } else {
          setAvailability([]);
          setShowLoader(false);
        }

        checkAvailability(startDate, sch_ids);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  const checkAvailability = async (startDate, schids) => {
    try {
      const availabilityResults = {};

      // Convert startDate to moment object
      const startMoment = moment(startDate);

      // Loop through the abbreviated weekdays
      for (const day of weekdays1) {
        const fullDay = weekdayMap[day]; // Get the full name from the map

        // Calculate the specific weekdate for the current day
        const weekdate = startMoment.clone().day(fullDay); // Format as YYYY-MM-DD
        // If the calculated date is before the start date, add 7 days to move to the next week
        if (weekdate.isBefore(startMoment)) {
          weekdate.add(7, "days");
        }

        // Format as YYYY-MM-DD
        const formattedWeekdate = weekdate.format("YYYY-MM-DD");

        let formData = {
          weekdate: formattedWeekdate, // Include the calculated weekdate
          sch_id: schids,
        };

        console.log(formData);
        const response = await makePostRequest(
          "employee/showWeeklyAvailabilityStatusToAdmin",
          formData
        );

        // console.log("day : ",day+" avail: ",response.data.data[0].is_available)
        if (response.data.data.length > 0)
          availabilityResults[day] = response.data.data[0].is_available;
        // true or false
        else availabilityResults[day] = false;
      }

      setAvailabilityStatus(availabilityResults);
    } catch (error) {
      console.error("Error fetching availability:", error);
    }
  };

  const [employee, setEmployee] = React.useState([]);

  async function getEmployeePreferencesForAdmin() {
    let bodyFormData = {
      search: search,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };
    await makePostRequest(
      "schedular/getEmployeePreferencesForAdmin",
      bodyFormData
    )
      .then((response) => {
        if (response.data) {
          console.log(response.data.data);
          setEmployee(response.data.data);
        } else {
          setEmployee([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [search, setSearch] = React.useState("");
  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getEmployeePreferencesForAdmin();
  }, []);

  const deleteTemplate = async (template_name) => {
    setShowLoader(true);

    swal({
      title: "Are you sure?",
      text: "You want to delete this template.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          let bodyFormData = {
            template_name,
          };
          const response = await makePostRequest(
            "schedular/deleteTemplate",
            bodyFormData
          );
          if (response.data.data) {
            setShowLoader(false);
            getAllScheduleList();
            getAllArchiveTemplateList();
          }
        } catch (error) {
          console.error(`Error in archiving:`, error);
        }
      } else {
        //swal("Your file is safe!");
      }
    });
  };

  const archiveTemplate = async (template_name) => {
    setShowLoader(true);

    swal({
      title: "Are you sure?",
      text: "You want to archive this template.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          let bodyFormData = {
            template_name,
          };
          const response = await makePostRequest(
            "schedular/archiveTemplate",
            bodyFormData
          );
          if (response.data.data) {
            setShowLoader(false);
            getAllScheduleList();
            getAllArchiveTemplateList();
          }
        } catch (error) {
          console.error(`Error in archiving:`, error);
        }
      } else {
        //swal("Your file is safe!");
      }
    });
  };

  //end code

  return (
    <>
      <div className="wrapper">
        <div className="taskListAdmMain forUpperBottomBt">
          {/* TopBar */}
          <div className="innerPageTopSecBackPatch"></div>
          <div className="innerPageTopSec">
            <div className="forBackArrow forAppDrawer">
              <AppDrawer />
            </div>
            <h1>
              <marquee>Manage Tasks & Category</marquee>
            </h1>
            {/* <IconButton
              className="addEmpTpBt"
              onClick={() => navigate("/AdminAddTask")}
            >
              <AddIcon />
            </IconButton> */}

            {/* <div className="myFlotingBtMain adminTaskFloatingMain">
              <div class="fab-wrapper">
                <input id="fabCheckbox" type="checkbox" class="fab-checkbox" />
                <label class="fab" for="fabCheckbox">
                  <span class="fab-dots fab-dots-1"></span>
                  <span class="fab-dots fab-dots-2"></span>
                  <span class="fab-dots fab-dots-3"></span>
                </label>
                <div class="fab-wheel">
                  <a
                    class="fab-action fab-action-1"
                    onClick={() => navigate("/AdminAddTask")}
                  >
                    <span className="floatinTx">Add Task</span>
                    <AddIcon />
                  </a>
                  <a
                    class="fab-action fab-action-2"
                    onClick={() => navigate("/AddFrequency")}
                  >
                    <span className="floatinTx">Add Frequency</span>
                    <AddIcon />
                  </a>
                  <a
                    class="fab-action fab-action-3"
                    onClick={() => navigate("/AddCategory")}
                  >
                    <span className="floatinTx">Add Category</span>
                    <AddIcon />
                  </a>
                  <a
                    class="fab-action fab-action-4"
                    onClick={() => navigate("/CreateNewSchedule")}
                  >
                    <span className="floatinTx">Create New Schedule</span>
                    <AddIcon />
                  </a>
                </div>
              </div>
            </div> */}
            <div className="forBackArrow"></div>
          </div>
          {/* TopBarEnd */}

          <div className="tabsMainD addTaskNCateTabMain">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                  scrollButtons="auto"
                  className={`kkk ${scrolling ? "scrolledTab" : ""}`}
                >
                  <Tab label="Tasks List" {...a11yProps(0)} />
                  <Tab label="All Tasks" {...a11yProps(1)} />
                  <Tab label="Employee Shifts" {...a11yProps(2)} />
                  <Tab label="Schedule" {...a11yProps(3)} />
                  <Tab label="Location" {...a11yProps(4)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <div className="tabInrContDiv">
                  {/* <Button
                    className="logginBt addNewB"
                    onClick={() => navigate("/AddCategory")}
                  >
                    Add Tasks List
                  </Button> */}
                  <div className="tabMickMainInp tabMickMainInpnew myMick">
                    {isListeningCategory ? (
                      <img src={speak_animation} className="voiceAnimation" />
                    ) : null}
                    <input
                      type="text"
                      placeholder="Speak To Add New Task"
                      name="category_name"
                      id="category_name"
                      value={Category}
                    />
                    <SettingsVoiceOutlinedIcon
                      className="microphone"
                      onClick={handleStartListeningCategory}
                      disabled={isListeningCategory}
                    />
                  </div>
                  <div className="listingSearch">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => getAllCategoryList(e.target.value)}
                    />
                    <div className="sortingIcoOut">
                      <ToggleButton
                        value="check"
                        selected={selected}
                        onClick={() => handleChangeSortByCategory()}
                      >
                        <ImportExportIcon
                          className="visitorFilterIco"
                          aria-haspopup="true"
                        />
                      </ToggleButton>
                    </div>
                  </div>
                  <div className="employyeListingMain">
                    {categoryList ? (
                      <>
                        {categoryList.map((cateObj) => (
                          <div
                            id={"bugfree-" + cateObj.categoryid}
                            // className={`swipe-container ${
                            //   swipeDirection === "left" ? "swiped-left" : ""
                            //   } ${swipeDirection === "right" ? "swiped-right" : ""}`}
                            className={"swipe-container"}
                            onTouchStart={(e) =>
                              handleTouchStart(e, cateObj.categoryid)
                            }
                            onTouchMove={(e) =>
                              handleTouchMove(e, cateObj.categoryid)
                            }
                            onClick={(e) =>
                              handleTouchEnd(e, cateObj.categoryid)
                            }
                          >
                            <div className="deleEdiBtLeft">
                              <IconButton onClick={() => editCategory(cateObj)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate("/CategoryDetails", {
                                    state: { Id: cateObj.categoryid },
                                  })
                                }
                              >
                                <InfoOutlinedIcon />
                              </IconButton>
                            </div>
                            <div
                              className="emploTaskListInr"
                              id="listID"
                              onClick={swipeNSelection}
                            >
                              <div>
                                <h1>{cateObj.category_name}</h1>
                                {cateObj.frequancy_ids == 0 ? (
                                  <p className="errorSt">
                                    Please Add Employee Shift for This Task
                                  </p>
                                ) : null}
                              </div>
                              {/* <div className="taskImUpload">
                          <input type="file" />
                          <FilterIcon onClick={handleClickOpen} />
                        </div> */}
                            </div>
                            <div className="deleEdiBtRight">
                              <IconButton onClick={resetSwipeDirection}>
                                <RestartAltIcon className="RestartAltIcon" />
                              </IconButton>
                              {addcat(
                                selectedCategorylist,
                                cateObj.categoryid
                              ) ? (
                                <></>
                              ) : (
                                <IconButton
                                  onClick={() =>
                                    deleteCategory(cateObj.categoryid)
                                  }
                                >
                                  <DeleteIcon className="DeleteIcon" />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* <div className="upperBottomBt">
                    <Button className="logginBt">Schedule Tasks</Button>
                  </div> */}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <div className="tabInrContDiv">
                  {/*  <Button
                    className="logginBt addNewB"
                    onClick={() => navigate("/AdminAddTask")}
                  >
                    Add Task
                  </Button>*/}
                  <div className="tabMickMainInp tabMickMainInpnew myMick">
                    {isListening ? (
                      <img src={speak_animation} className="voiceAnimation" />
                    ) : null}
                    <input
                      type="text"
                      placeholder="Speak To Add New Task"
                      name="task_name"
                      id="task_name"
                      value={taskname}
                    />
                    <SettingsVoiceOutlinedIcon
                      className="microphone"
                      onClick={handleStartListening}
                      disabled={isListening}
                    />
                  </div>
                  <div className="listingSearch">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => getAllTaskList(e.target.value)}
                    />
                    <div onClick={() => handleChangeSortByTask()}>
                      <div className="sortingIcoOut">
                        <ToggleButton
                          value="check"
                          selected={selected}
                          onChange={() => {
                            handleChangeSortBy();
                          }}
                        >
                          <ImportExportIcon
                            className="visitorFilterIco"
                            aria-haspopup="true"
                          />
                        </ToggleButton>
                      </div>
                    </div>
                  </div>
                  <div className="employyeListingMain">
                    {taskList ? (
                      <>
                        {taskList.map((taskObj) => (
                          <div
                            id={"bugfree-" + taskObj.taskid}
                            // className={`swipe-container ${
                            //   swipeDirection === "left" ? "swiped-left" : ""
                            //   } ${swipeDirection === "right" ? "swiped-right" : ""}`}
                            className="swipe-container"
                            onTouchStart={(e) =>
                              handleTouchStart(e, taskObj.taskid)
                            }
                            onTouchMove={(e) =>
                              handleTouchMove(e, taskObj.taskid)
                            }
                            onClick={(e) => handleTouchEnd(e, taskObj.taskid)}
                          >
                            <div className="deleEdiBtLeft">
                              <IconButton onClick={() => editTask(taskObj)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  navigate("/TaskDetails", {
                                    state: { Id: taskObj.taskid },
                                  })
                                }
                              >
                                <InfoOutlinedIcon />
                              </IconButton>
                            </div>
                            <div
                              className="emploTaskListInr"
                              id="listID"
                              onClick={swipeNSelection}
                            >
                              <div>
                                <h1>{taskObj.task_name}</h1>
                              </div>
                              <div className="taskImUpload">
                                {/* <FilterIcon onClick={handleClickOpen} /> */}
                                {taskObj.is_beforeafter == true ? (
                                  <div className="taskImUpload">
                                    <FilterIcon />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                              {/* <div className="taskImUpload">
                          <input type="file" />
                          <FilterIcon onClick={handleClickOpen} />
                        </div> */}
                            </div>
                            <div className="deleEdiBtRight">
                              <IconButton onClick={resetSwipeDirection}>
                                <RestartAltIcon className="RestartAltIcon" />
                              </IconButton>

                              {add(selectedTaskList, taskObj.taskid) ? (
                                <></>
                              ) : (
                                <IconButton
                                  onClick={() => deleteTask(taskObj.taskid)}
                                >
                                  <DeleteIcon className="DeleteIcon" />
                                </IconButton>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* <div className="upperBottomBt">
                    <Button className="logginBt">Schedule Tasks</Button>
                  </div> */}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <div className="tabInrContDiv">
                  {/* <Button
                    className="logginBt addNewB"
                    onClick={() => navigate("/AddFrequency")}
                  >
                    Add Employee Shifts
                  </Button> */}

                  {/* Commented By Kailas */}
                  <div className="tabMickMainInp tabMickMainInpnew myMick">
                    {isListeningFrequency ? (
                      <img src={speak_animation} className="voiceAnimation" />
                    ) : null}
                    <input
                      type="text"
                      placeholder="Speak To Add New Shift"
                      name="frequancy_name"
                      id="frequancy_name"
                      value={frequency}
                    />
                    <SettingsVoiceOutlinedIcon
                      className="microphone"
                      onClick={handleStartListeningFrequency}
                      disabled={isListeningFrequency}
                    />
                  </div>
                  <div className="listingSearch">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => getAllFrequancyList(e.target.value)}
                    />
                    <div className="sortingIcoOut">
                      <ToggleButton
                        value="check"
                        selected={selected}
                        onClick={() => handleChangeSortByFrequency()}
                      >
                        <ImportExportIcon
                          className="visitorFilterIco"
                          aria-haspopup="true"
                        />
                      </ToggleButton>
                    </div>
                  </div>

                  <div className="employyeListingMain">
                    {frequancyList ? (
                      <>
                        {frequancyList.map((frequObj) => (
                          <div
                            id={"bugfree-" + frequObj.frequancyid}
                            className={"swipe-container"}
                            onTouchStart={(e) =>
                              handleTouchStart(e, frequObj.frequancyid)
                            }
                            onTouchMove={(e) =>
                              handleTouchMove(e, frequObj.frequancyid)
                            }
                            onClick={(e) =>
                              handleTouchEnd(e, frequObj.frequancyid)
                            }
                          >
                            <div className="deleEdiBtLeft">
                              <IconButton
                                onClick={() => editFrequency(frequObj)}
                              >
                                <EditIcon />
                              </IconButton>
                            </div>
                            <div
                              className="emploTaskListInr"
                              id="listID"
                              onClick={swipeNSelection}
                            >
                              <div className="activDeactSt">
                                <h1>{frequObj.frequancy_name}</h1>
                                <span>
                                  {frequObj.status == true ? (
                                    <p className="actSt">Active</p>
                                  ) : (
                                    <p className="deActSt">Deactive</p>
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="deleEdiBtRight">
                              <IconButton onClick={resetSwipeDirection}>
                                <RestartAltIcon className="RestartAltIcon" />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  deleteFrequency(frequObj.frequancyid)
                                }
                              >
                                <DeleteIcon className="DeleteIcon" />
                              </IconButton>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/*<div className="upperBottomBt">
                    <Button className="logginBt">Schedule Tasks</Button>
                  </div>*/}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <div className="tabInrContDiv">
                  <Button
                    className="logginBt addNewB"
                    onClick={() => navigate("/CreateNewSchedule")}
                  >
                    Create New Schedule
                  </Button>
                  <div className="schedulAcordianMain">
                    {schedulelist.map((schlist, index) => (
                      <div className="schedulAcordianInr">
                        <div className="schedulAcordianTp">
                          <b>
                            {schlist.template_name}
                            <IconButton
                              aria-label="edit"
                              onClick={() =>
                                editSchedule(schlist, schlist.template_name)
                              }
                            >
                              <img src={pencil} />
                            </IconButton>
                          </b>
                          <div className="schedulAcordianTpRight">
                            <IconButton
                              aria-label="edit"
                              onClick={() =>
                                handleClickOpenAval(schlist.sch_ids)
                              }
                            >
                              {schlist.is_active && (
                                <img src={availabilityPreview} />
                              )}
                            </IconButton>
                            {!schlist.is_active && (
                              <>
                                <IconButton>
                                  <DeleteIcon
                                    className="DeleteIcon"
                                    onClick={() =>
                                      deleteTemplate(schlist.template_name)
                                    }
                                  />
                                </IconButton>
                                <ArchiveOutlinedIcon
                                  className="archiveIcon"
                                  onClick={() =>
                                    archiveTemplate(schlist.template_name)
                                  }
                                />
                              </>
                            )}
                            <Switch
                              {...label}
                              checked={schlist.is_active} // Set the checked state based on is_active
                              onChange={(event) =>
                                handleSwitchChange(
                                  event,
                                  index,
                                  schlist.template_name
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="schedulAcordianCont">
                          <div className="tabsMainD tabsMainDInr">
                            <Box sx={{ width: "100%" }}>
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                <Tabs
                                  value={tabValues[schlist.template_name] || 0}
                                  onChange={(event, newValue) =>
                                    handleChangeTab(
                                      schlist.template_name,
                                      newValue
                                    )
                                  }
                                  aria-label="basic tabs example"
                                  variant="scrollable"
                                  scrollButtons="auto"
                                >
                                  {schlist.distinct_locations.map(
                                    (loc, locIndex) => (
                                      <Tab
                                        key={locIndex}
                                        label={loc}
                                        {...a11yProps2({ locIndex })}
                                        onClick={() =>
                                          getlocationwiseSummarycount(
                                            loc,
                                            schlist.template_name
                                          )
                                        }
                                      />
                                    )
                                  )}
                                </Tabs>
                              </Box>
                              {schlist.distinct_locations.map(
                                (loc, locIndex) => (
                                  <CustomTabPanel
                                    value={
                                      tabValues[schlist.template_name] || 0
                                    }
                                    index={locIndex}
                                    key={locIndex}
                                  >
                                    <div className="tabInrContDiv">
                                      <div className="shiftDaysMain">
                                        <div className="shiftDaysInr">
                                          <div className="weeklyTaskTbl">
                                            <table width="100%">
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <b>
                                                      D<em>ays</em>
                                                    </b>
                                                  </td>
                                                  <td>M</td>
                                                  <td>T</td>
                                                  <td>W</td>
                                                  <td>T</td>
                                                  <td>F</td>
                                                  <td>S</td>
                                                  <td style={{ color: "red" }}>
                                                    S
                                                  </td>
                                                </tr>
                                                {/* Shifts Row */}
                                                <tr>
                                                  <td>
                                                    <b>
                                                      S<em>hifts</em>
                                                    </b>
                                                  </td>
                                                  {weekdays.map((day) => (
                                                    <td key={day}>
                                                      {templateDayData[
                                                        schlist.template_name
                                                      ]?.shifts[day] || 0}
                                                    </td>
                                                  ))}
                                                </tr>

                                                {/* Employees Row */}
                                                <tr>
                                                  <td>
                                                    <b>
                                                      E<em>mp</em>
                                                    </b>
                                                  </td>
                                                  {weekdays.map((day) => (
                                                    <td key={day}>
                                                      {templateDayData[
                                                        schlist.template_name
                                                      ]?.emp[day] || 0}
                                                    </td>
                                                  ))}
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </CustomTabPanel>
                                )
                              )}
                            </Box>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div className="archiveListingMain">
                      <h1 className="archiveListingHed">Archive</h1>
                      {archive.length > 0 ? (
                        archive.map((data, index) => (
                          <div className="schedulAcordianTp" key={index}>
                            <b>{data}</b>
                            <div className="schedulAcordianTpRight">
                              <ArchiveOutlinedIcon
                                className="archiveIcon"
                                onClick={() => navigate("/ArchivePage")}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No Archive Templates</p>
                      )}
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <div className="tabInrContDiv">
                  <div className="tabMickMainInp tabMickMainInpnew">
                    <input
                      type="text"
                      placeholder="Speak To Add New Task"
                      name="category_name"
                      id="category_name"
                      value={Category}
                    />
                    <SettingsVoiceOutlinedIcon
                      className="microphone"
                      onClick={handleStartListeningCategory}
                      disabled={isListeningCategory}
                    />
                  </div>
                  <div className="listingSearch">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={(e) => getAllCategoryList(e.target.value)}
                    />
                    <div className="sortingIcoOut">
                      <ToggleButton
                        value="check"
                        selected={selected}
                        onClick={() => handleChangeSortByCategory()}
                      >
                        <ImportExportIcon
                          className="visitorFilterIco"
                          aria-haspopup="true"
                        />
                      </ToggleButton>
                    </div>
                  </div>
                  <div className="employyeListingMain">
                    <div
                      className={`swipe-container ${
                        swipeDirection === "left" ? "swiped-left" : ""
                      } ${swipeDirection === "right" ? "swiped-right" : ""}`}
                      onTouchStart={handleTouchStart}
                      onTouchMove={handleTouchMove2}
                      onTouchEnd={handleTouchEnd}
                    >
                      <div className="deleEdiBtLeft">
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </div>
                      <div
                        className="emploTaskListInr"
                        id="listID"
                        onClick={swipeNSelection}
                      >
                        <div>
                          <h1>Location Name</h1>
                        </div>
                        <div className="taskImUpload">
                          {/* <input type="file" /> */}
                          <FilterIcon onClick={handleClickOpen} />
                        </div>
                      </div>
                      <div className="deleEdiBtRight">
                        <IconButton onClick={resetSwipeDirection}>
                          <RestartAltIcon className="RestartAltIcon" />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon className="DeleteIcon" />
                        </IconButton>
                      </div>
                    </div>

                    <div className="emploTaskListInr">
                      <div>
                        <h1>Location Name</h1>
                      </div>
                      <div className="taskImUpload">
                        {/* <input type="file" /> */}
                        <FilterIcon onClick={handleClickOpen} />
                      </div>
                    </div>

                    <div className="emploTaskListInr">
                      <div>
                        <h1>Location Name</h1>
                      </div>
                      <div className="taskImUpload">
                        {/* <input type="file" /> */}
                        <FilterIcon onClick={handleClickOpen} />
                      </div>
                    </div>

                    <div className="emploTaskListInr">
                      <div>
                        <h1>Location Name</h1>
                      </div>
                      <div className="taskImUpload">
                        {/* <input type="file" /> */}
                        <FilterIcon onClick={handleClickOpen} />
                      </div>
                    </div>

                    <div className="emploTaskListInr">
                      <div>
                        <h1>Location Name</h1>
                      </div>
                      <div className="taskImUpload">
                        {/* <input type="file" /> */}
                        <FilterIcon onClick={handleClickOpen} />
                      </div>
                    </div>
                  </div>
                </div>
              </CustomTabPanel>
            </Box>
          </div>

          <AdminBottomNav />
        </div>
      </div>

      {/* BeforAfterImage */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">Before & After Pictures</h1>
              <IconButton className="modalCloseIco" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="beforAftMain">
                    <div className="beforSt">
                      <h1 className="befAftHeading">Before</h1>
                      <div className="befAftImgDiv">
                        <img src={afterBef1} />
                      </div>
                    </div>
                    <div className="afterSt">
                      <h1 className="befAftHeading">After</h1>
                      <div className="befAftImgDiv">
                        <img src={afterBef2} />
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {/* Availabiliy */}
      <div className="AvailabilityPopUp">
        <React.Fragment>
          <Dialog
            open={openAval}
            onClose={handleCloseAval}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle id="alert-dialog-title">
              <h1 className="modalHeading">
                <marquee>Employee Availability Information</marquee>
              </h1>
              <IconButton className="modalCloseIco" onClick={handleCloseAval}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="shifAvailabiMain">
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <div className="mySlik createScheduSlik">
                          <div className="slider-container">
                            <Slider
                              {...settings}
                              afterChange={(index) =>
                                handleMondayChange(mondays[index])
                              }
                            >
                              {mondays.map((monday, index) => (
                                <div key={index}>
                                  <h3>Mon</h3>
                                  <span>
                                    {monday.format("MM/DD/YYYY")}
                                  </span>{" "}
                                  {/* Format the date as desired */}
                                </div>
                              ))}
                            </Slider>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <div
                          className="setDayMain myAvailibiSetDay "
                          style={{ marginBottom: 10 }}
                        >
                          <div
                            className={`dayInD preferanceBts ${
                                abb == 'Pref' ? "activeIndicator" : ""
                              } `}
                            onClick={handlePreferencesClick}
                          >
                            <img src={userWithCheck} style={{ width: 17 }} />
                            <span></span>
                          </div>

                          {weekdayAbbr.map((abbreviation) => (
                            <button
                              key={abbreviation}
                              onClick={() => handleDayClick(abbreviation)}
                              className={`dayInD ${
                                availabilitystatus[abbreviation]
                                  ? "activDs"
                                  : ""
                              } ${
                                abb == abbreviation ? "activeIndicator" : ""
                              } `}
                            >
                              {abbreviation}
                              <span></span>
                            </button>
                          ))}
                        </div>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <div className="daySlider emplShifSlider">
                          <Slider ref={sliderRef} {...settings2}>
                            <div>
                              <h3 className="slidDayHeading newSLideDayHeading">
                                <b>Preferences</b>
                              </h3>
                              <div className="preferenceTableOuter">
                                <table width="100%">
                                  <thead>
                                    <tr className="preferenceTableHeading">
                                      <th>
                                        <p className="preferenceTableP">
                                          Employee Name
                                        </p>
                                      </th>
                                      <th>
                                        <p className="preferenceTableP">
                                          Hours
                                        </p>
                                      </th>
                                      <th>
                                        <p className="preferenceTableP">
                                          Shifts
                                        </p>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {employee.map((emp, index) => (
                                      <tr>
                                        <td>
                                          <div className="preferenceEmpData">
                                            <div className="preferenceEmpDataInner">
                                              <Zoom>
                                                <img
                                                  src={
                                                    emp.profile_pic != null &&
                                                    emp.profile_pic != ""
                                                      ? emp.profile_pic
                                                      : profilePic
                                                  }
                                                />
                                              </Zoom>
                                            </div>
                                            <h1>{emp.full_name}</h1>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="preferenceHours">
                                            <p>
                                              {emp.no_of_hours > 0
                                                ? emp.no_of_hours + "Hrs"
                                                : "-"}
                                            </p>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="preferenceShifts">
                                            <p>
                                              {emp.no_of_shifts > 0
                                                ? emp.no_of_shifts + "Shifts"
                                                : "-"}{" "}
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            {Object.keys(availability).length > 0 ? (
                              Object.keys(availability).map((day, index) => (
                                <div>
                                  <h3 className="slidDayHeading newSLideDayHeading">
                                    <b>{day}</b>
                                    <span>{weekdayDates[day]}</span>
                                  </h3>
                                  <div
                                    className="schedulAcordianMain"
                                    style={{ marginBottom: 20 }}
                                  >
                                    {availability[day].map((locationData) => (
                                      <div
                                        className="schedulAcordianInr"
                                        key={locationData.location}
                                      >
                                        <div className="schedulAcordianTp">
                                          <b>{locationData.location}</b>
                                          <div className="schedulAcordianTpRight">
                                            {locationData.status ===
                                            "not ok" ? (
                                              <ErrorOutlineIcon
                                                style={{
                                                  color: "red",
                                                }}
                                              />
                                            ) : (
                                              <PlaylistAddCheckIcon
                                                style={{
                                                  color: "#0FB000",
                                                }}
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="schedulAcordianCont">
                                          {locationData.shifts.map((shift) => (
                                            <div
                                              className="ShifPreviewModCont"
                                              key={shift.shift_timing}
                                            >
                                              <h1>
                                                {shift.shift_timing} (
                                                {shift.avail_emp_count}/
                                                {shift.emp_count})
                                                {shift.status === "not ok" && (
                                                  <ErrorOutlineIcon
                                                    style={{
                                                      color: "red",
                                                    }}
                                                  />
                                                )}
                                              </h1>
                                              {shift.employees.map(
                                                (employee) => (
                                                  <div
                                                    className="connectionsDiv"
                                                    style={{
                                                      marginBottom: 0,
                                                    }}
                                                    key={employee.emp_id}
                                                  >
                                                    <div className="pendingReIm">
                                                      <img
                                                        src={
                                                          employee.profile_pic ||
                                                          avarPi
                                                        }
                                                        alt={employee.name}
                                                      />
                                                    </div>
                                                    <div className="pendingRex prefranceTx">
                                                      <h1> {employee.name} </h1>
                                                      <p></p>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div>No availability data found.</div>
                            )}
                          </Slider>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {showLoader ? (
        <div className="loaderNew">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default AdminTasksList;
