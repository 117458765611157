import React from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import avtarPic from "../../../assets/images/avtarPic.png";
import avtarPic2 from "../../../assets/images/Profile-Picture.jpg";

// Modal
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
// End

import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import picPro from "../../../assets/images/avtarPic.png";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Button, Grid } from "@mui/material";

// For Accorddian
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// End

import Slider from "react-slick";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import picAv from "../../../assets/images/Profile-Picture.jpg";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import UserBottom from "../UserBottom/UserBottom";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import swap from "../../../assets/images/swap.svg";
import forword from "../../../assets/images/forwardall.svg";
import swapWhite from "../../../assets/images/swapwhite.svg";
import forwordWhite from "../../../assets/images/offer-white.svg";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function ShiftSwapAndOffer() {
  const navigate = useNavigate();

  function swapDrawerCl() {
    var element = document.getElementById("swapId");
    element.classList.toggle("addSwap");
  }

  function openBotDraw() {
    var element = document.getElementById("swapId");
    element.classList.toggle("openBotDrawCl");
  }

  function hideShowdrwrdata() {
    var element = document.getElementById("hideShoId");
    element.classList.toggle("hideShowdrwrSt");
  }

  // For Toggle Button
  const [alignment, setAlignment] = React.useState("");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  // End

  const searchSlide = () => {
    var element = document.getElementById("searchSlideId");
    element.classList.toggle("searchSlideClass");
  };

  const settings = {
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // For Schedul Preview
  const [Preview, setOpenPreview] = React.useState(false);

  const handleClickPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };
  // End

  // Assign Shift Moddal
  const [AssignShift, setOpenAssignShift] = React.useState(false);

  const handleClickAssignShift = () => {
    setOpenAssignShift(true);
  };

  const handleCloseAssignShift = () => {
    setOpenAssignShift(false);
  };
  // End

  // Change Shift Moddal
  const [ChangeShift, setOpenChangeShift] = React.useState(false);

  const handleClickChangeShift = () => {
    setOpenChangeShift(true);
  };

  const handleCloseChangeShift = () => {
    setOpenChangeShift(false);
  };
  // End

  // Swap Shift Moddal
  const [Swap, setOpenSwap] = React.useState(false);

  const handleClickSwap = () => {
    setOpenSwap(true);
  };

  const handleCloseSwap = () => {
    setOpenSwap(false);
  };
  // End

  // Another Swap Shift Moddal
  const [AnotherSwap, setOpenAnotherSwap] = React.useState(false);

  const handleClickAnotherSwap = () => {
    setOpenAnotherSwap(true);
  };

  const handleCloseAnotherSwap = () => {
    setOpenAnotherSwap(false);
  };
  // End

  return (
    <>
      <div className="wrapper" style={{ paddingBottom: 0 }}>
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch" style={{ height: 65 }}></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            {/* <NavigateBeforeIcon onClick={() => navigate(-1)} /> */}
          </div>
          <h1>Shift Swap & Offer</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}
      </div>

      <div className="schedlPreviewMain" id="swapId">
        <div className="forNotificationMain">
          <Slider {...settings}>
            <div>
              <div className="notificationMain">
                <div className="notificationTp">
                  <div className="notificationProp">
                    <img src={picAv} />
                  </div>
                  <h1>
                    Jenny Wilson <span>offering shift for location</span>{" "}
                    <b>Housekeeping</b> <em>09:30am - 12:30pm</em>
                  </h1>
                </div>
                <div className="rejNAcepBts">
                  <Button>Reject</Button>
                  <Button style={{ color: "#0FB000" }}>Accept</Button>
                </div>
              </div>
            </div>

            <div>
              <div className="notificationMain">
                <div className="notificationTp">
                  <div className="notificationProp">
                    <img src={picAv} />
                  </div>
                  <h1>
                    Cameron Williamson{" "}
                    <span>want’s to swap shift with you for</span>{" "}
                    <b>Kitchen</b>
                  </h1>
                </div>
                <div className="swapDataMain">
                  <div className="swapDatTop wantToSwapMain">
                    <div className="swapDatInr">
                      <div className="assignShiProLine">
                        <div className="swapShiRight">
                          <h1>Tuesday 01/05/2024</h1>
                          <p>09:30am - 12:30pm</p>
                        </div>
                      </div>
                      <SwapHorizontalCircleIcon className="swpIc" />
                    </div>

                    <div className="swapDatInr">
                      <div className="assignShiProLine">
                        <div className="swapShiRight">
                          <h1>Friday 01/06/2024</h1>
                          <p>09:30am - 12:30pm</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rejNAcepBts">
                    <Button>Reject</Button>
                    <Button style={{ color: "#0FB000" }}>Accept</Button>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>

        <div className="schedledLin">
          Shift schedule for week <b>Monday 1/1/24 - Sunday 1/7/24</b>
        </div>

        <div className="daySlider emplShifSlider schedPreviewSlideMain">
          <h5 className="stickHeading">
            <Button></Button>
            Kitchen{" "}
            <Button>
              Next <ArrowDownwardTwoToneIcon />
            </Button>
          </h5>
          <div className="swapOfrBtnOuter">
            <div className="swapOfrBtnMain">
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="swap" onClick={swapDrawerCl}>
                  <img src={swap} className="swpBlc" />
                  <img src={swapWhite} className="swpWt" /> Swap
                </ToggleButton>
                <ToggleButton value="offer">
                  <img src={forword} className="swpBlc" />
                  <img src={forwordWhite} className="swpWt" /> Offer
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className="schedulPreviewTblMain">
            <div className="tblSearchEmp" id="searchSlideId">
              <SearchIcon />
              <input type="text" placeholder="Search" />
            </div>
            <div class="table-wrapper">
              <table width="100%">
                <thead>
                  <tr>
                    <th class="fixed-column">
                      <div className="tblSearchIcoOt" onClick={searchSlide}>
                        <PersonSearchIcon />
                      </div>
                    </th>
                    <th>
                      <p>
                        <span>M</span> <span>1/1</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>T</span> <span>1/2</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>W</span> <span>1/3</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>T</span> <span>1/4</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>F</span> <span>1/5</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>S</span> <span>1/6</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>SU</span> <span>1/7</span>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Audrey</h1>
                      </p>
                    </td>
                    <td className="selctedSwap">
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Gloria</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv">
                        <h1 className="unAvailableEmp">U</h1>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Claire</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td className="selctedSwap">
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickAnotherSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Jane</h1>
                      </p>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Serenity</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv">
                        <h1 className="unAvailableEmp">U</h1>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Jane</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <h5 className="stickHeading">
            <Button>
              <ArrowUpwardTwoToneIcon /> Previous
            </Button>
            Housekeeping
            <Button>
              Next <ArrowDownwardTwoToneIcon />
            </Button>
          </h5>
          <div className="swapOfrBtnOuter">
            <div className="swapOfrBtnMain">
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="swap" onClick={swapDrawerCl}>
                  <img src={swap} className="swpBlc" />
                  <img src={swapWhite} className="swpWt" /> Swap
                </ToggleButton>
                <ToggleButton value="offer">
                  <img src={forword} className="swpBlc" />
                  <img src={forwordWhite} className="swpWt" /> Offer
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className="schedulPreviewTblMain">
            <div className="tblSearchEmp" id="searchSlideId">
              <SearchIcon />
              <input type="text" placeholder="Search" />
            </div>
            <div class="table-wrapper">
              <table width="100%">
                <thead>
                  <tr>
                    <th class="fixed-column">
                      <div className="tblSearchIcoOt" onClick={searchSlide}>
                        <PersonSearchIcon />
                      </div>
                    </th>
                    <th>
                      <p>
                        <span>M</span> <span>1/1</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>T</span> <span>1/2</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>W</span> <span>1/3</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>T</span> <span>1/4</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>F</span> <span>1/5</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>S</span> <span>1/6</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>SU</span> <span>1/7</span>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Audrey</h1>
                      </p>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Gloria</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv">
                        <h1 className="unAvailableEmp">U</h1>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Claire</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Jane</h1>
                      </p>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Serenity</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv">
                        <h1 className="unAvailableEmp">U</h1>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Jane</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <h5 className="stickHeading">
            <Button>
              <ArrowUpwardTwoToneIcon /> Previous
            </Button>
            FrontDesk
            <Button>
              Next <ArrowDownwardTwoToneIcon />
            </Button>
          </h5>
          <div className="swapOfrBtnOuter">
            <div className="swapOfrBtnMain">
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
              >
                <ToggleButton value="swap" onClick={swapDrawerCl}>
                  <img src={swap} className="swpBlc" />
                  <img src={swapWhite} className="swpWt" /> Swap
                </ToggleButton>
                <ToggleButton value="offer">
                  <img src={forword} className="swpBlc" />
                  <img src={forwordWhite} className="swpWt" /> Offer
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className="schedulPreviewTblMain">
            <div className="tblSearchEmp" id="searchSlideId">
              <SearchIcon />
              <input type="text" placeholder="Search" />
            </div>
            <div class="table-wrapper">
              <table width="100%">
                <thead>
                  <tr>
                    <th class="fixed-column">
                      <div className="tblSearchIcoOt" onClick={searchSlide}>
                        <PersonSearchIcon />
                      </div>
                    </th>
                    <th>
                      <p>
                        <span>M</span> <span>1/1</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>T</span> <span>1/2</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>W</span> <span>1/3</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>T</span> <span>1/4</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>F</span> <span>1/5</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>S</span> <span>1/6</span>
                      </p>
                    </th>
                    <th>
                      {" "}
                      <p>
                        <span>SU</span> <span>1/7</span>
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Audrey</h1>
                      </p>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Gloria</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv">
                        <h1 className="unAvailableEmp">U</h1>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Claire</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Jane</h1>
                      </p>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Serenity</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv">
                        <h1 className="unAvailableEmp">U</h1>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Jane</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Audrey</h1>
                      </p>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Gloria</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv">
                        <h1 className="unAvailableEmp">U</h1>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Claire</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Jane</h1>
                      </p>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Serenity</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv">
                        <h1 className="unAvailableEmp">U</h1>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="fixed-column">
                      <p className="dayNDateCol">
                        <h1>Jane</h1>
                      </p>
                    </td>
                    <td>
                      <div className="swapEmpDiv standBy">
                        <h1>SB</h1>
                        {/* <AddCircleIcon
                          className="sbIcon"
                          onClick={() => handleClickAssignShift("body")}
                        /> */}
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                    <td>
                      <div
                        className="swapEmpDiv"
                        onClick={() => handleClickSwap("body")}
                      >
                        <h1>A</h1>
                        <AddCircleIcon className="sbIcon" />
                        <span>9:30 - 12:30</span>
                        <span>12:30 - 2:30</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="patchForSwapDraw"></div>

        <div className="swapDrawerMain" id="hideShoId">
          <h3 className="indicatSwapHead" onClick={openBotDraw}>
            Select a shift to swap{" "}
            <KeyboardArrowUpIcon className="drwrIcoArro" />
          </h3>

          <div className="underProcessDataMain hideD2">
            <h4>Select a shift to swap</h4>
            <div className="swapDatInr">
              <div className="assignShiProLine">
                <div className="assignShiProLineInr">
                  <div className="assignShiPro">
                    <img src={picPro} />
                  </div>
                  <h1>Audrey</h1>
                </div>
                <div className="swapShiRight">
                  <h1>Tuesday 01/02/2024</h1>
                  <p>09:30am - 12:30pm</p>
                </div>
              </div>
            </div>

            <h4>Choose another shift to swap with</h4>
            <div className="swapDatInr">
              <div className="assignShiProLine">
                <div className="assignShiProLineInr">
                  <div className="assignShiPro">
                    <img src={picPro} />
                  </div>
                  <h1>Audrey</h1>
                </div>
                <div className="swapShiRight">
                  <h1>Tuesday 01/02/2024</h1>
                  <p>09:30am - 12:30pm</p>
                </div>
              </div>
            </div>
          </div>

          <div className="swapDataMain hideD1">
            <SwapVerticalCircleIcon className="swpIc" />
            <div className="swapDatTop">
              <div className="swapDatInr">
                <div className="assignShiProLine">
                  <div className="assignShiProLineInr">
                    <div className="assignShiPro">
                      <img src={picPro} />
                    </div>
                    <h1>Audrey</h1>
                  </div>
                  <div className="swapShiRight">
                    <h1>Tuesday 01/02/2024</h1>
                    <p>09:30am - 12:30pm</p>
                  </div>
                </div>
              </div>

              <div className="swapDatInr">
                <div className="assignShiProLine">
                  <div className="assignShiProLineInr">
                    <div className="assignShiPro">
                      <img src={picPro} />
                    </div>
                    <h1>jack M</h1>
                  </div>
                  <div className="swapShiRight">
                    <h1>Friday 01/06/2024</h1>
                    <p>09:30am - 12:30pm</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="rejNAcepBts">
              <Button>Cancel</Button>
              <Button style={{ color: "#0FB000" }}>Request Swap</Button>
            </div>
          </div>
        </div>
      </div>

      <UserBottom />

      {/* Assign Shift Moddal */}
      {/* <div className="">
        <React.Fragment>
          <Dialog
            open={AssignShift}
            onClose={handleCloseAssignShift}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ background: "#f6f6f6" }}
            >
              <h1
                className="modalHeading"
                style={{ marginTop: 0, paddingTop: 25 }}
              >
                Assign shift to <b style={{ color: "#990000" }}>Audrey</b> for
                the
                <br />
                <b className="alrdLoc">
                  Kitchen on Wednesday <span>01/03/2024</span>
                </b>
              </h1>
              <IconButton
                className="modalCloseIco"
                onClick={handleCloseAssignShift}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="previewScheduModMain">
                    <div className="assignedShiftDivMain">
                      <div className="assignedShiftInr">
                        <div className="dayWInr">
                          <span>09:30am To 12:30pm</span>
                          <b>
                            <PeopleOutlineIcon /> 2
                          </b>
                        </div>

                        <div className="assignShiProLine">
                          <div className="assignShiProLineInr assigPlac">
                            <div className="assignShiPro">
                              <img src={picPro} />
                            </div>
                            <h1>Audrey</h1>
                          </div>
                          <div className="relesD">
                            <Button className="SiftAssignToBt">Assign</Button>
                          </div>
                        </div>

                        <label class="assignedShi">Assigned Shifts</label>
                        <div className="assignShiProLine">
                          <div className="assignShiProLineInr">
                            <div className="assignShiPro">
                              <img src={picPro} />
                            </div>
                            <h1>Jenny Wilson</h1>
                          </div>
                          <div className="relesD">
                            Release <CloseIcon className="relesEmpIco" />
                          </div>
                        </div>
                        <div className="assignShiProLine">
                          <div className="assignShiProLineInr">
                            <div className="assignShiPro">
                              <img src={picPro} />
                            </div>
                            <h1>Jack Warren</h1>
                          </div>
                          <div className="relesD">
                            Release <CloseIcon className="relesEmpIco" />
                          </div>
                        </div>
                      </div>

                      <div className="assignedShiftInr">
                        <div className="dayWInr">
                          <span>01:30pm To 03:00pm</span>
                          <b>
                            <PeopleOutlineIcon /> 1
                          </b>
                        </div>

                        <div className="assignShiProLine">
                          <div className="assignShiProLineInr assigPlac">
                            <div className="assignShiPro">
                              <img src={picPro} />
                            </div>
                            <h1>Audrey</h1>
                          </div>
                          <div className="relesD">
                            <Button className="SiftAssignToBt">Assign</Button>
                          </div>
                        </div>

                        <label class="assignedShi">Assigned Shifts</label>
                        <div className="assignShiProLine">
                          <div className="assignShiProLineInr">
                            <div className="assignShiPro">
                              <img src={picPro} />
                            </div>
                            <h1>Theresa Webb</h1>
                          </div>
                          <div className="relesD">
                            Release <CloseIcon className="relesEmpIco" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div> */}

      {/* Change Shift Moddal */}
      {/* <div className="">
        <React.Fragment>
          <Dialog
            open={ChangeShift}
            onClose={handleCloseChangeShift}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ background: "#f6f6f6" }}
            >
              <h1
                className="modalHeading"
                style={{ marginTop: 0, paddingTop: 25 }}
              >
                Change shift of <b style={{ color: "#990000" }}>Audrey</b> for
                the
                <br />
                <b className="alrdLoc">
                  Housekeeping on Tuesday <span>01/02/2024</span>
                </b>
              </h1>
              <IconButton
                className="modalCloseIco"
                onClick={handleCloseChangeShift}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="swapDataMain">
                    <SwapVerticalCircleIcon className="swpIc" />
                    <div className="swapDatTop">
                      <div className="swapDatInr">
                        <div className="assignShiProLine">
                          <div className="assignShiProLineInr">
                            <div className="assignShiPro">
                              <img src={picPro} />
                            </div>
                            <h1>Audrey</h1>
                          </div>
                          <div className="swapShiRight">
                            <h1>Tuesday 01/02/2024</h1>
                            <p>09:30am - 12:30pm</p>
                          </div>
                        </div>
                      </div>

                      <div className="swapDatInr">
                        <div className="assignShiProLine">
                          <div className="assignShiProLineInr">
                            <div className="assignShiPro">
                              <img src={picPro} />
                            </div>
                            <h1>jack M</h1>
                          </div>
                          <div className="swapShiRight">
                            <h1>Friday 01/06/2024</h1>
                            <p>09:30am - 12:30pm</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rejNAcepBts">
                      <Button>Reject</Button>
                      <Button style={{ color: "#0FB000" }}>Accept</Button>
                    </div>
                  </div>

                  <div className="previewScheduModMain">
                    <div className="assignedShiftDivMain">
                      <div className="shiftAlertMsg">
                        <CheckCircleIcon />
                        2:30pm To 5:30pm Shift assigned successfully to Audrey
                        for Kitchen on Friday 01/02/2024
                      </div>
                      <div
                        className="changeShifLine"
                        style={{ marginBottom: 0 }}
                      >
                        <label className="assignedShi">Assigned Shifts</label>
                        <p>
                          09:30am To 12:30pm{" "}
                          <em>
                            Release <CloseIcon className="relesEmpIco" />
                          </em>
                        </p>
                        <p>
                          12:30pm To 03:30pm{" "}
                          <em>
                            Release <CloseIcon className="relesEmpIco" />
                          </em>
                        </p>
                      </div>
                      <div
                        className="schedularAccordianMain"
                        style={{ marginTop: 15 }}
                      >
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Typography>Assign Shift</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                  <select className="myInput">
                                    <option>Select Location</option>
                                    <option>Kitchen</option>
                                    <option>Housekeeping</option>
                                    <option>Parking</option>
                                  </select>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <select className="myInput">
                                    <option>Select Shift</option>
                                    <option>09:30am To 12:30pm</option>
                                    <option>12:30am To 4:30pm</option>
                                  </select>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                  <div className="modNewBtnOuter">
                                    <Button>Save</Button>
                                  </div>
                                </Grid>
                              </Grid>
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div> */}

      {/* Swap Shift Moddal */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={Swap}
            onClose={handleCloseSwap}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ background: "#f6f6f6" }}
            >
              <h1
                className="modalHeading"
                style={{ marginTop: 0, paddingTop: 25 }}
              >
                Select Shifts to Swap
                <br />
                <b className="alrdLoc">
                  Kitchen on Tuesday <span>01/02/2024</span>
                </b>
              </h1>
              <IconButton className="modalCloseIco" onClick={handleCloseSwap}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="previewScheduModMain">
                    <div className="assignedShiftDivMain">
                      <div className="assignedShiftInr">
                        <label class="assignedShi">Select</label>

                        <div className="forSwpCk">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="09:30am - 12:30pm"
                              />
                              <FormControlLabel
                                control={<Checkbox />}
                                label="12:30pm - 02:30pm"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="modNewBtnOuter">
                                <Button>Ok</Button>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>

      {/* Another Swap Shift Moddal */}
      <div className="">
        <React.Fragment>
          <Dialog
            open={AnotherSwap}
            onClose={handleCloseAnotherSwap}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="myAppModalMain forBeforAfterMod"
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ background: "#f6f6f6" }}
            >
              <h1
                className="modalHeading"
                style={{ marginTop: 0, paddingTop: 25 }}
              >
                Select Shifts to Swap with <b>Claire</b>
                <br />
                <b className="alrdLoc">
                  Kitchen on Tuesday <span>01/02/2024</span>
                </b>
              </h1>
              <IconButton
                className="modalCloseIco"
                onClick={handleCloseAnotherSwap}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 0 }}>
              <DialogContentText id="alert-dialog-description">
                <div className="modalBodyCont">
                  <div className="previewScheduModMain">
                    <div className="assignedShiftDivMain">
                      <div className="assignedShiftInr">
                        <label class="assignedShi">Select</label>

                        <div className="forSwpCk">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                              <FormControlLabel
                                control={<Checkbox defaultChecked />}
                                label="09:30am - 12:30pm"
                              />
                              <FormControlLabel
                                control={<Checkbox />}
                                label="12:30pm - 02:30pm"
                              />
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <div className="modNewBtnOuter">
                                <Button onClick={hideShowdrwrdata}>Ok</Button>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </React.Fragment>
      </div>
    </>
  );
}

export default ShiftSwapAndOffer;
