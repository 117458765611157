import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const DragNDropTabsDemo = () => {
  const [tabs, setTabs] = useState([
    { id: "1", label: "Tab 1" },
    { id: "2", label: "Tab 2" },
    { id: "3", label: "Tab 3" },
    { id: "4", label: "Tab 4" },
  ]);

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleDragEnd = (result) => {
    const { destination, source } = result;

    // If dropped outside the droppable area, do nothing
    if (!destination) return;

    // If the item is dropped at the same place, do nothing
    if (destination.index === source.index) return;

    // Reorder the tabs based on the result of drag-and-drop
    const reorderedTabs = Array.from(tabs);
    const [removed] = reorderedTabs.splice(source.index, 1);
    reorderedTabs.splice(destination.index, 0, removed);

    // Update the tabs state with the new order
    setTabs(reorderedTabs);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-tabs" direction="horizontal">
        {(provided) => (
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            ref={provided.innerRef} // Properly bind droppable container
            {...provided.droppableProps} // Properly assign droppable props
            style={{ display: "flex", overflowX: "auto" }} // Make tabs horizontally scrollable
          >
            {tabs.map((tab, index) => (
              <Draggable key={tab.id} draggableId={tab.id} index={index}>
                {(provided) => (
                  <Tab
                    label={tab.label}
                    value={index}
                    key={tab.id}
                    ref={provided.innerRef} // Assign the drag handle correctly
                    {...provided.draggableProps} // Enable drag functionality
                    {...provided.dragHandleProps} // Enable dragging with mouse
                    style={{
                      ...provided.draggableProps.style, // Ensure the tab follows drag behavior
                      marginRight: 8, // Space between tabs
                      cursor: "move", // Change cursor to move when dragging
                    }}
                  />
                )}
              </Draggable>
            ))}
            {provided.placeholder}{" "}
            {/* Placeholder adjusts layout while dragging */}
          </Tabs>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragNDropTabsDemo;
