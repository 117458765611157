import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Box } from "@mui/material";
import "react-medium-image-zoom/dist/styles.css";
import AdminBottomNav from "../AdminBottomNav/AdminBottomNav";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../FormikControl";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BeenhereOutlinedIcon from "@mui/icons-material/BeenhereOutlined";
import LabelOffOutlinedIcon from "@mui/icons-material/LabelOffOutlined";

function EditTaskList() {
  const navigate = useNavigate();

  function labDrop() {
    var element = document.getElementById("labDropId");
    element.classList.toggle("labDropClass");
  }

  //   For Drop Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // End

  //   For Validation
  const initialValues = {
    firstname: "",
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required("Required First Name"),
  });

  const onSubmit = (values) => console.log("Form Data", values);

  return (
    <>
      <div className="wrapper">
        {/* TopBar */}
        <div className="innerPageTopSecBackPatch"></div>
        <div className="innerPageTopSec">
          <div className="forBackArrow">
            <NavigateBeforeIcon onClick={() => navigate(-1)} />
          </div>
          <h1>Edit Task List</h1>
          <div className="forBackArrow"></div>
        </div>
        {/* TopBarEnd */}

        <div className="addAdminEmployeeMain forUpperBottomBt">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <FormikControl
                      control="input"
                      type="text"
                      label="Task List Name"
                      placeholder="Task List Name"
                      labelClass="myLable"
                      name="firstname"
                      className="myInput"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="labelDropMain">
                      <div className="labelDropHead" onClick={labDrop}>
                        <div className="labelDropHeadLab">
                          Summer Template<em className="pulse green"></em>
                        </div>
                        <ArrowDropDownIcon />
                      </div>
                      <div className="labDropCont" id="labDropId">
                        <div className="labDropContInr" onClick={labDrop}>
                          <h1>
                            Summer Template<em className="pulse green"></em>
                          </h1>
                          <p>
                            <BeenhereOutlinedIcon /> Shifts Assigned
                          </p>
                        </div>
                        <div className="labDropContInr" onClick={labDrop}>
                          <h1>
                            Winter Template
                            {/* <em></em> */}
                          </h1>
                          <p>
                            <LabelOffOutlinedIcon
                              style={{ color: "#ff4c4c" }}
                            />{" "}
                            No Shifts Assigned
                          </p>
                        </div>
                        <div className="labDropContInr" onClick={labDrop}>
                          <h1>
                            Snow Template
                            {/* <em></em> */}
                          </h1>
                          <p>
                            <LabelOffOutlinedIcon
                              style={{ color: "#ff4c4c" }}
                            />{" "}
                            No Shifts Assigned
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="setDayMain">
                      <div className="dayInD">M</div>
                      <div className="dayInD activDs">T</div>
                      <div className="dayInD">W</div>
                      <div className="dayInD">Th</div>
                      <div className="dayInD">F</div>
                      <div className="dayInD">S</div>
                      <div className="dayInD">Su</div>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className="ediTaskListMain">
                      <div className="ediTaskListAllSele">
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="Kitchen"
                        />
                        <div className="seleLabelD">
                          <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                          >
                            Select label <ArrowDropDownIcon />
                          </Button>

                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            className="seleLabelDIn"
                          >
                            <MenuItem onClick={handleClose}>
                              Opening Shift
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                              Closing Shift
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                              Opening Shift
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                      <div className="ediTaskListAddedShif">
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="09:30am - 12:30pm"
                        />
                        <p>Opening Shift</p>
                      </div>
                      <div className="ediTaskListAddedShif">
                        <FormControlLabel
                          control={<Checkbox />}
                          label="12:30pm - 02:30pm"
                        />
                        <p className="noShiftAdded">No shift Label Assigned</p>
                      </div>
                      <div className="ediTaskListAddedShif">
                        <FormControlLabel
                          control={<Checkbox />}
                          label="04:30pm - 07:30pm"
                        />
                        <p>Closing Shift</p>
                      </div>
                    </div>

                    <div className="ediTaskListMain">
                      <div className="ediTaskListAllSele">
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="Housekeeping"
                        />
                        <div className="seleLabelD">
                          <Button
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                          >
                            Select label <ArrowDropDownIcon />
                          </Button>

                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            className="seleLabelDIn"
                          >
                            <MenuItem onClick={handleClose}>
                              Opening Shift
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                              Closing Shift
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                              Opening Shift
                            </MenuItem>
                          </Menu>
                        </div>
                      </div>
                      <div className="ediTaskListAddedShif">
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="09:30am - 12:30pm"
                        />
                        <p>Opening Shift</p>
                      </div>
                      <div className="ediTaskListAddedShif">
                        <FormControlLabel
                          control={<Checkbox />}
                          label="12:30pm - 02:30pm"
                        />
                        <p className="noShiftAdded">No shift Label Assigned</p>
                      </div>
                      <div className="ediTaskListAddedShif">
                        <FormControlLabel
                          control={<Checkbox />}
                          label="04:30pm - 07:30pm"
                        />
                        <p>Closing Shift</p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <AdminBottomNav />
    </>
  );
}

export default EditTaskList;
